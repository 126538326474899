import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
console.log('Init Sentry');
Sentry.init({
  dsn: 'https://709a2726074f5dffeeb282c46eccce24@o4507347831947264.ingest.us.sentry.io/4507347833585664',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      maskAllText: false,
      blockAllMedia: false
  })],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
